import React from "react";
import {Link} from "gatsby";

import Layout from "../../components/layout";
import SEO from "../../components/seo";
import Pricing from "../../components/pricing";
import VrIllustration from "../../images/vr-illustration.png";

function VisitePage() {
  return (
    <Layout>
      <SEO
        title="Visites virtuelles 3D Matterport"
      />
      <div className="mx-auto max-w-screen-xl">
        <div className="mt-4 mx-auto max-w-screen-xl px-4 sm:mt-6 sm:px-6 md:mt-10 xl:mt-12">
            <h1>Visite virtuelle 3D Matterport pour l'immobilier</h1>


            <div className="relative mt-4 lg:grid lg:grid-cols-2 lg:gap-8 lg:items-center">
              <div className="relative"> 
                  <h2 className="mt-2 text-gray-900 text-xl leading-8 font-extrabold tracking-tight">
                  Comment une agence immobilière peut-elle obtenir plus de mandats exclusifs ?
                  </h2>
                  <p className="mt-3 text-lg leading-7">
                  Quelques années en arrière, peu d’agences proposaient des <strong>photographies professionnelles</strong> à leurs clients. Maintenant ces photographies sont la norme et ceux qui n’en font toujours pas ont un sérieux désavantage par rapport à leurs concurrents dans la captation de nouveaux mandats.
                  </p>
                  <p className="mt-3 text-lg leading-7">Aujourd’hui grâce aux nouvelles technologies, notamment l’intelligence artificielle développée par desentreprises comme Matterport, la visite virtuelle 3D est enfin accessible aux <strong>agences immobilières</strong> et aux <strong>coachs immobilers</strong>.</p>
                  <p className="mt-3 text-lg leading-7">Les plus véloces en prennent déjà acte et profitent de cette technologie pour se démarquer et <strong>capter plus de mandats</strong>, comme ils l’ont fait auparavant avec la photographie.</p>
              </div>
              <div className="mt-10 -mx-4 relative lg:mt-0 lg:row-start-1 lg:row-end-2">
                <img className="relative mx-auto" src={VrIllustration} alt="Visites virtuelles 3D Matterport" />
              </div>
            </div>
 
            <div className="bg-white overflow-hidden">
              <div className="relative max-w-screen-xl mx-auto pt-20 px-4 sm:px-6 lg:px-8">
            
                <div className="relative lg:flex lg:items-center">

                  <div className="relative lg:ml-10">
                    <svg className="absolute top-0 left-0 transform -translate-x-8 -translate-y-24 h-36 w-36 text-red-200 opacity-50" stroke="currentColor" fill="none" viewBox="0 0 144 144">
                      <path strokeWidth="2" d="M41.485 15C17.753 31.753 1 59.208 1 89.455c0 24.664 14.891 39.09 32.109 39.09 16.287 0 28.386-13.03 28.386-28.387 0-15.356-10.703-26.524-24.663-26.524-2.792 0-6.515.465-7.446.93 2.327-15.821 17.218-34.435 32.11-43.742L41.485 15zm80.04 0c-23.268 16.753-40.02 44.208-40.02 74.455 0 24.664 14.891 39.09 32.109 39.09 15.822 0 28.386-13.03 28.386-28.387 0-15.356-11.168-26.524-25.129-26.524-2.792 0-6.049.465-6.98.93 2.327-15.821 16.753-34.435 31.644-43.742L121.525 15z" />
                    </svg>
                    <blockquote>
                      <div className="text-2xl leading-9 font-medium text-red-600">
                        <p>
                        80% des français préfèrent confier leur bien en mandat exclusif à une agence immobilière proposant des visites virtuelles.
                        </p>
                      </div>                      
                    </blockquote>
                  </div>
                </div>
              </div>
            </div>

              <div className="py-12">
    <div className="lg:text-center">
      <h2 className="mt-2 text-3xl leading-8 font-extrabold tracking-tight text-gray-900">
      Démarquez vous de la concurrence avec une solution Tout-en-un
      </h2>
    </div>

    <div className="mt-10">
      <ul className="md:grid md:grid-cols-2 md:col-gap-8 md:row-gap-10">
        <li>
          <div className="flex">
            <div className="flex-shrink-0">
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">

                <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M12 8v4l3 3m6-3a9 9 0 11-18 0 9 9 0 0118 0z"></path>
                </svg>
              </div>
            </div>
            <div className="ml-4">
              <h5 className="text-lg leading-6 font-medium text-gray-900">Résultat en 24h</h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
              Avec une seule modélisation vous bénéficiez sous 24h de tous les éléments marketing visuels pour vendre un bien plus efficacement.
              </p>
            </div>
          </div>
        </li>
        <li className="mt-10 md:mt-0">
          <div className="flex">
            <div className="flex-shrink-0">
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
              <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M13.828 10.172a4 4 0 00-5.656 0l-4 4a4 4 0 105.656 5.656l1.102-1.101m-.758-4.899a4 4 0 005.656 0l4-4a4 4 0 00-5.656-5.656l-1.1 1.1"></path>
              </svg>
              </div>
            </div>
            <div className="ml-4">
              <h5 className="text-lg leading-6 font-medium text-gray-900">Visualisation très simple</h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
              Envoyez un simple lien à vos prospects afin qu’ils puissent se faire une idée précise du bien grâce à la meilleure visite virtuelle 3D du marché, et ne vous déplacez que pour des prospects qualifiés. Moins de déplacements inutiles et des propriétaires moins dérangés.
              </p>
            </div>
          </div>
        </li>
        <li className="mt-10 md:mt-0">
          <div className="flex">
            <div className="flex-shrink-0">
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
                <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" className="h-6 w-6">
                  <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M3 9a2 2 0 012-2h.93a2 2 0 001.664-.89l.812-1.22A2 2 0 0110.07 4h3.86a2 2 0 011.664.89l.812 1.22A2 2 0 0018.07 7H19a2 2 0 012 2v9a2 2 0 01-2 2H5a2 2 0 01-2-2V9z"></path>
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M15 13a3 3 0 11-6 0 3 3 0 016 0z"></path>
                </svg>
              </div>
            </div>
            <div className="ml-4">
              <h5 className="text-lg leading-6 font-medium text-gray-900">Des photos HD pour vos annonces</h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
              Valorisez vos annonces avec de belles photos HD issues de la visite virtuelle, et grâce à notre expertise en photographie immobilière nous choisirons des emplacements stratégiques et graphiques pour ces photos.
              </p>
            </div>
          </div>
        </li>
        <li className="mt-10 md:mt-0">
          <div className="flex">
            <div className="flex-shrink-0">
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
              <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M8.684 13.342C8.886 12.938 9 12.482 9 12c0-.482-.114-.938-.316-1.342m0 2.684a3 3 0 110-2.684m0 2.684l6.632 3.316m-6.632-6l6.632-3.316m0 0a3 3 0 105.367-2.684 3 3 0 00-5.367 2.684zm0 9.316a3 3 0 105.368 2.684 3 3 0 00-5.368-2.684z"></path>
              </svg>
              </div>
            </div>
            <div className="ml-4">
              <h5 className="text-lg leading-6 font-medium text-gray-900">Vidéos et panoramiques 360°</h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
              Générez plus de visites sur votre site internet grâce aux minis vidéos et aux panoramiques 360° à intégrer sur les réseaux sociaux.
              </p>
            </div>
          </div>
        </li>
        <li className="mt-10 md:mt-0">
          <div className="flex">
            <div className="flex-shrink-0">
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
              <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 5a1 1 0 011-1h14a1 1 0 011 1v2a1 1 0 01-1 1H5a1 1 0 01-1-1V5zM4 13a1 1 0 011-1h6a1 1 0 011 1v6a1 1 0 01-1 1H5a1 1 0 01-1-1v-6zM16 13a1 1 0 011-1h2a1 1 0 011 1v6a1 1 0 01-1 1h-2a1 1 0 01-1-1v-6z"></path>
              </svg>
              </div>
            </div>
            <div className="ml-4">
              <h5 className="text-lg leading-6 font-medium text-gray-900">Plans schématiques en 2D</h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
              Proposez les plans schématiques en 2D à vos prospects afin de les aider à se projeter encore plus.
               
              </p>
            </div>
          </div>
        </li>
        <li className="mt-10 md:mt-0">
          <div className="flex">
            <div className="flex-shrink-0">
              <div className="flex items-center justify-center h-12 w-12 rounded-md bg-red-500 text-white">
              <svg fill="none" viewBox="0 0 24 24" stroke="currentColor" className="w-6 h-6">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M7 21a4 4 0 01-4-4V5a2 2 0 012-2h4a2 2 0 012 2v12a4 4 0 01-4 4zm0 0h12a2 2 0 002-2v-4a2 2 0 00-2-2h-2.343M11 7.343l1.657-1.657a2 2 0 012.828 0l2.829 2.829a2 2 0 010 2.828l-8.486 8.485M7 17h.01"></path>
              </svg>
              </div>
            </div>
            <div className="ml-4">
              <h5 className="text-lg leading-6 font-medium text-gray-900">Fichiers pour logiciels d'architectures</h5>
              <p className="mt-2 text-base leading-6 text-gray-500">
              Pour les biens à rénover ou pour le homestaging virtuel, sont disponible les fichiers .xyz (nuage de points) et .obj (maillage 3D) directement exploitables dans les logiciels d’architecture (Revit, AutoCAD, ArchiCAD, SketchUp, 3ds Max, etc).
              </p>
            </div>
          </div>
        </li>

      </ul>
    </div>
  </div>

  <div className="text-center mt-10 mb-12">
    <h2 className="mb-5 text-gray-900 text-xl leading-9 font-extrabold tracking-tight sm:text-2xl sm:leading-10">Exemple de réalisation <Link to="/realisations" title="Nos réalisations de visites virtuelles 3D" className="text-base text-sm font-semibold underline">(Voir toutes nos réalisations)</Link></h2>      
    <div className="iframe-container">
      <iframe src='https://my.matterport.com/show/?m=zv7PvsxDc7X'></iframe>
    </div>
  </div>

    </div>
      </div>



    <Pricing />
    </Layout>
  );
}

export default VisitePage;
